import React, { useCallback } from 'react'

import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { DeploymentConfig, GeoLocation } from '@viewlio/types/src'
import { AgeGateActions, AgeGateUIPolicy, AgeGateConfig } from '@viewlio/types/src/AgeGate'
import cookie from 'js-cookie'
import { GetStaticProps } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { CanadaAgeGateProps } from 'components/ageGate/components/CanadaAgeGate'
import { DefaultAgeGateProps } from 'components/ageGate/components/DefaultAgeGate'
import { AgeGateContext } from 'components/ageGate/contexts'
import { ageGateActions, ageGateUIPolicy, getMessages } from 'components/ageGate/utils'
import { useGlobalStore, useAgeGateStatusStore } from 'stores'
import { getCommonStaticProps } from 'utils/dataFetching/getCommonStaticProps'
import { withStoreCustomization } from 'utils/hocs/withStoreCustomization'
import { redirectToRelativeUrl } from 'utils/urls/redirectToRelativeUrl'

const DefaultAgeGate = dynamic(() => import('components/ageGate/components/DefaultAgeGate').then(mod => mod.DefaultAgeGate))
const CanadaAgeGate = dynamic(() => import('components/ageGate/components/CanadaAgeGate').then(mod => mod.CanadaAgeGate))

const AgeGateBase = withStoreCustomization<
  DefaultAgeGateProps | CanadaAgeGateProps
>({
  'juul-ca': CanadaAgeGate,
}, DefaultAgeGate)

const AgeGate = () => {
  const router = useRouter()

  const {
    shopper: {
      geoLocation, meta: { loaded },
    },
    juulioStore: {
      code,
      ageGate,
      statesWithDynamicPricing,
    },
  } = useGlobalStore()

  const isGeolocationLoading =
    !loaded && viewlioConfig.stores[code].ageGate.geolocation

  const { options: { expiresDays } } = ageGate

  const windowLocation = {
    assign: router.push,
    pathname: router.asPath,
  }

  const config: AgeGateConfig = {
    ...ageGate as any,
    cookie,
    expiresDays,
    geolocation: geoLocation as Required<Pick<GeoLocation, 'city' | 'stateAbbr'>>,
    routerQuery: router.query,
    statesWithDynamicPricing: statesWithDynamicPricing,
    windowLocation,
  }

  const policy: AgeGateUIPolicy = ageGateUIPolicy(config)
  const actions: AgeGateActions =
    ageGateActions(config, router, policy)

  const messageKeys = getMessages(
    policy.isWarrantyAgeGate ? 'warranty' : 'regular',
  )

  const handleAgreeButtonClick = useCallback((stateCode: string) => {
    if (!router.isReady) return

    actions.handleAgreeButtonClick(stateCode)

    useGlobalStore.setState({
      selectedState: stateCode,
    })

    useAgeGateStatusStore.setState({ isAgeGateVerified: true })

    // Must check redirectUrl is a relative URL before sending users
    // there. This is to avoid bad-actors from sending users to e.g.:
    // /age-gate?redirect=https://MALICIOUS-SITE.COM
    redirectToRelativeUrl(router.query.redirect as string)
  }, [router.isReady])

  const props = {
    ageGateUIPolicy: policy,
    config,
    isGeolocationLoading,
    onAgreeButtonClick: handleAgreeButtonClick,
    onRouteToLocatorButtonClick: actions.handleRouteToLocatorButtonClick,
    shouldTrackAgeGateEvents:
      viewlioConfig.stores[code].ageGate.shouldTrackAgeGateEvents,
  }

  return (
    <AgeGateContext.Provider value={{ messageKeys }}>
      <AgeGateBase {...props} />
    </AgeGateContext.Provider>
  )
}

export const deploymentConfig: DeploymentConfig = {
  attributes: ['age-gate'],
  environments: {
    '*': {
      'juul-ca': true,
      'juul-it': true,
      'juul-uk': true,
      'juul-us': true,
    },
  },
}

export const getStaticProps: GetStaticProps = async ({
  locale,
}) => getCommonStaticProps({
  deploymentConfig,
  locale,
  metaEntryLookup: 'age-gate',
},async () => ({
  skipAgeVerification: true,
  skipPhoneVerification: true,
}))

export default AgeGate
